import React, { useEffect, useState } from 'react';
import apiUrl from '../Api/api';
import axios from 'axios';
import AdminHeader from './AdminHeader';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Import the Search icon



const TotalDonors = () => {
    const [donors, setDonors] = useState([]); // State for donor data
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

    useEffect(() => {
        const fetchData = async () => {
            try {
                const donorsResponse = await axios.get(`${apiUrl}/admin/donors/total`);
                setDonors(donorsResponse.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when changing rows per page
    };

    // Filter donors based on search query
    const filteredDonors = donors.filter((customer) =>
        customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.mobile.includes(searchQuery) ||
        customer.address1.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.address2.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const reversedOrder = [...filteredDonors].slice().reverse();

    return (
        <div className='admin-container'>
            <AdminHeader />
            <Box sx={{ flexGrow: 1, padding: 2, py: { xs: 5, md: 15 } }}>

                <Typography variant='h5' align='center' sx={{ p: 2 }}>Total Donors</Typography>

                <Box sx={{ width: "100%", textAlign: "end" }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search by Name, Email, Phone, or Address"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ mb: 2, width: 350, }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Paper elevation={3}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Address</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Joined On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reversedOrder.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer, index) => (
                                    <TableRow key={index}>
                                        {/* <TableCell>{page * rowsPerPage + index + 1}</TableCell> */}
                                        <TableCell>{customer.donor_id}</TableCell>
                                        <TableCell>{customer.name}</TableCell>
                                        <TableCell>{customer.email}</TableCell>
                                        <TableCell>{customer.mobile}</TableCell>
                                        <TableCell>{customer.address1} <br /> {customer.address2}</TableCell>
                                        <TableCell>{new Date(customer.time_stamp).toLocaleString('en-GB', {
                                            timeZone: 'UTC', // Ensures the time is in UTC
                                            hour12: true,    // 12-hour format
                                            year: 'numeric',
                                            day: '2-digit',
                                            month: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredDonors.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    );
};

export default TotalDonors;
