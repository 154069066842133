import React, { useState } from 'react';
import { TextField, Button, MenuItem, Grid, Typography, Container, ThemeProvider, Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import axios from 'axios';
import AdminHeader from './AdminHeader';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from 'react-country-state-city';
import {
    FormControl,
    InputLabel,
    Select,
    Snackbar,
    Alert,
} from '@mui/material';
import 'react-country-state-city/dist/react-country-state-city.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import withAuth from '../HighOrderComponent/withAuth';
import apiUrl from '../Api/api';


const AddDonor = ({ loading, error, handleLoading, handleError }) => {


    const [donorDetails, setDonorDetails] = useState({
        donor_category: '',
        rep_id: '0',
        prefix: '',
        name: '',
        isd_code: '91',
        mobile: '',
        email: '',
        address1: '',
        address2: '',
        city_name: '',
        state_name: '',
        country_name: '',
        pincode: '',
        pan: '',
        admin_id: '1',
        status: 'Y', // Default status can be 'A' for Active
    });

    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDonorDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const [selectedOption, setSelectedOption] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const handleRadioChange = (event) => {

        const { name, value } = event.target;
        setSelectedOption(value);
        console.log(value);
        setDonorDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        // Update dropdown options based on selected radio button
        if (value === 'Individual') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        } else if (value === 'Institution') {
            setDropdownOptions(['M/s']);
        } else if (value === 'Representative') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        handleLoading(true)
        try {
            const response = await axios.post(`${apiUrl}/admin/add-donor`, donorDetails);
            handleLoading(false)
            console.log(donorDetails);
            // Reset the form
            setDonorDetails({
                donor_category: '',
                rep_id: '',
                prefix: '',
                name: '',
                isd_code: '',
                mobile: '',
                email: '',
                address1: '',
                address2: '',
                city_name: '',
                state_name: '',
                country_name: '',
                pincode: '',
                pan: '',
                admin_id: '',
                status: 'A',
            });
            setSnackbarMessage(response.data.message);
            setOpenSnackbar(true);
        } catch (error) {
            handleError(error.response.data.message || error.response.data.error || "An error occured")
            console.error('Error adding donor:', error);
        }
    };



    return (
        <Box>
            <AdminHeader />
            <Container sx={{ mt: { xs: 5, md: 15 }, mb: 4 }}>
                <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }} gutterBottom>
                    Add Donor
                </Typography>

                {error && <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at the top-right
                >
                    <Alert onClose={() => setOpenSnackbar(false)}
                        severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>}
                {loading && <Typography sx={{ p: 2 }}>Loading...</Typography>}

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ mt: 2 }}>

                        <Grid item xs={12} sm={6}>
                            <FormControl component="fieldset" fullWidth >
                                <RadioGroup
                                    row
                                    aria-label="user-type"
                                    name="donor_category"
                                    value={selectedOption}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                                    <FormControlLabel value="Institution" control={<Radio />} label="Institution" />
                                    <FormControlLabel value="Representative" control={<Radio />} label="Representative" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" gap={5} width="100%">

                                <FormControl variant="outlined" sx={{ minWidth: 120, maxWidth: 100 }} >
                                    <InputLabel>Prefix</InputLabel>
                                    <Select
                                        value={donorDetails.prefix}
                                        onChange={handleChange}
                                        name="prefix"
                                        label="Prefix"
                                        required
                                    >
                                        {dropdownOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={donorDetails.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                type='email'
                                value={donorDetails.email}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" width="100%">
                                {/* ISD Code Input */}
                                <PhoneInput
                                    country={'in'}
                                    value={donorDetails.isd_code}
                                    onChange={(value) => {
                                        setDonorDetails({
                                            ...donorDetails,
                                            isd_code: value, // Store ISD code
                                        });
                                        console.log(value); // Log the phone number including the ISD code
                                    }}
                                    inputProps={{
                                        maxLength: 8, // Limit the length of the ISD code
                                    }}
                                />

                                {/* Mobile Number Input */}
                                <TextField
                                    fullWidth
                                    label="Mobile"
                                    name="mobile"
                                    value={donorDetails.mobile}
                                    onChange={handleChange}
                                    required
                                    inputProps={{
                                        maxLength: 10, // Limit the length of the ISD code
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}> {/* Adjust the size for responsiveness */}
                                    <TextField
                                        fullWidth
                                        label="Address 1"
                                        name="address1"
                                        value={donorDetails.address1}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}> {/* Adjust the size for responsiveness */}
                                    <TextField
                                        fullWidth
                                        label="Address 2"
                                        name="address2"
                                        value={donorDetails.address2}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>



                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Pincode"
                                name="pincode"
                                value={donorDetails.pincode}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="PAN"
                                name="pan"
                                value={donorDetails.pan}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Country, State, City Selects */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <CountrySelect
                                    onChange={(e) => {
                                        setCountryId(e.id);
                                        setStateId(0); // Reset state when country changes
                                        setDonorDetails({
                                            ...donorDetails,
                                            country_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    onBlur={(e) => {
                                        setCountryId(e.id);
                                        setStateId(0); // Reset state when country changes
                                        setDonorDetails({
                                            ...donorDetails,
                                            country_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    onTextChange={(e) => {
                                        setCountryId(e.id);
                                        setStateId(0); // Reset state when country changes
                                        setDonorDetails({
                                            ...donorDetails,
                                            country_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    required
                                    placeHolder="Select Country"
                                    sx={{ mb: 2 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" margin="normal" disabled={countryId === 0}>
                                <StateSelect
                                    countryid={countryId}
                                    onChange={(e) => {
                                        setStateId(e.id);
                                        setDonorDetails({
                                            ...donorDetails,
                                            state_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    onBlur={(e) => {
                                        setStateId(e.id);
                                        setDonorDetails({
                                            ...donorDetails,
                                            state_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    onTextChange={(e) => {
                                        setStateId(e.id);
                                        setDonorDetails({
                                            ...donorDetails,
                                            state_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    required
                                    placeHolder="Select State"
                                    sx={{ mb: 2 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" margin="normal" disabled={stateId === 0}>
                                <CitySelect
                                    countryid={countryId}
                                    stateid={stateId}
                                    onChange={(e) => {
                                        setDonorDetails({
                                            ...donorDetails,
                                            city_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    onBlur={(e) => {
                                        setDonorDetails({
                                            ...donorDetails,
                                            city_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    onTextChange={(e) => {
                                        setDonorDetails({
                                            ...donorDetails,
                                            city_name: e.target.value, // Assuming 'e.target.value' is the state name or ID you want to store
                                        });
                                    }}
                                    required
                                    placeHolder="Select City"
                                    sx={{ mb: 2 }}
                                />
                            </FormControl>
                        </Grid>

                        {/* Submit Button */}
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                            <Button variant="contained" color="primary" type="submit" size='large' sx={{ mt: 2, maxWidth: 400 }}>
                                Add Donor
                            </Button>
                        </Box>
                    </Grid>
                </form>

                {/* Snackbar for feedback */}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at the top-right
                >
                    <Alert onClose={() => setOpenSnackbar(false)}
                        severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
};

export default withAuth(AddDonor);
