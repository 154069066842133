import React, { useState } from 'react';
import {
    AppBar, Toolbar, Typography, Box, Drawer,
    IconButton, useMediaQuery, useTheme, ListItem, ListItemText, List, Button, Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Facebook, Twitter, Instagram, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Menu, MenuItem, } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // 992px

    const [drawerOpen, setDrawerOpen] = useState(false);


    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };



    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <Box>
            {/* Header Row 1 */}
            <AppBar position="static" sx={{ backgroundColor: 'white', p: 2 }}>
                <Toolbar sx={{ justifyContent: isSmallScreen ? "center" : 'space-between', alignItems: 'center' }}>
                    <Box sx={{ width: isSmallScreen ? "100%" : "", display: "flex", justifyContent: isSmallScreen ? "space-between" : '' }}>
                        <img src={require("../images/goveda-logo.png")} height={50} onClick={() => navigate("/")} loading='lazy' style={{ cursor: "pointer" }} />
                        {
                            isSmallScreen &&
                            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                                <MenuIcon />
                            </IconButton>
                        }
                    </Box>
                    {!isSmallScreen && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ mr: 2 }}>info@govedafoundation.org</Typography>
                            <Typography variant="body2" sx={{ mr: 2 }}>+91 7825807830</Typography>
                            <IconButton color="inherit" aria-label="facebook">
                                <Facebook />
                            </IconButton>
                            <IconButton color="inherit" aria-label="twitter">
                                <Twitter />
                            </IconButton>
                            <IconButton color="inherit" aria-label="instagram">
                                <Instagram />
                            </IconButton>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>

            {/* Header Row 2 */}
            <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main }}>
                <Toolbar>
                    {isSmallScreen ? (
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>

                            <Button
                                component={Link}
                                to="/donate"
                                variant="contained"
                                color="secondary"
                                sx={{
                                    ml: 2,
                                    color: '#fff',
                                    '&:hover': {
                                        // backgroundColor: '#003d00', // Darker green color on hover for the contained button
                                    },
                                }}
                            >
                                Donate Now
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <Button
                                component={Link}
                                to="/"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    backgroundColor: location.pathname === '/' ? '#178c5d' : '', // Active state color
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Home
                            </Button>
                            <Button
                                component={Link}
                                to="/about"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    backgroundColor: location.pathname === '/about' ? '#178c5d' : '', // Active state color
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                About
                            </Button>

                            <div className="navbar">
                                <div className="dropdown">
                                    <button className="dropbtn" >Causes <ArrowDropDownIcon /></button>
                                    <div className="dropdown-content goveda-dropdown-content">
                                        <div className="mega-dropdown">
                                            <div className="column">
                                                <a href="/causes/agnihotra-vruddhi" style={{ color: "white", fontWeight: "bold" }}>Agnihotra Vruddhi</a>
                                                <a href="/causes/annadanam" style={{ color: "white", fontWeight: "bold" }}>Annadanam</a>
                                                {/* <a href="#link3" style={{ color: "white", fontWeight: "bold" }}>Go Samrakshana</a> */}
                                                <a href="/causes/support-patashalas" style={{ color: "white", fontWeight: "bold" }}>Support Patashalas</a>
                                                {/* <a href="#link3" style={{ color: "white", fontWeight: "bold" }}>Vaiddeehal Matrimony</a> */}
                                                <a href="/causes/village-temple-parayanam" style={{ color: "white", fontWeight: "bold" }}>Village Temple Parayanam</a>
                                                {/* <a href="#link3" style={{ color: "white", fontWeight: "bold" }}>Zero Budget Natural Forming</a> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Button
                                component={Link}
                                to="/"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    backgroundColor: location.pathname === '/event' ? '#178c5d' : '', // Active state color
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Event
                            </Button>
                            <Button
                                component={Link}
                                to="/gallery"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    backgroundColor: location.pathname === '/gallery' ? '#178c5d' : '', // Active state color
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Gallery
                            </Button>
                            <Button
                                component={Link}
                                to="/contact"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    backgroundColor: location.pathname === '/contact' ? '#178c5d' : '', // Active state color
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Contact
                            </Button>
                            <Button
                                component={Link}
                                to="/dashboard"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    backgroundColor: location.pathname === '/dashboard' ? '#178c5d' : '', // Active state color
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Dashboard
                            </Button>
                            <Button
                                component={Link}
                                to="/donate"
                                variant="contained"
                                color="secondary"
                                sx={{
                                    ml: 2,
                                    backgroundColor: location.pathname === '/donate' ? '#178c5d' : '', // Active state color
                                    color: '#fff',
                                    fontWeight: "bold",
                                    '&:hover': {
                                        // backgroundColor: '#003d00', // Darker green color on hover for the contained button
                                    },
                                }}
                            >
                                Donate Now
                            </Button>
                        </Box>





                    )}
                </Toolbar>
            </AppBar>

            {/* Drawer */}
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                PaperProps={{
                    style: {
                        width: "80%", // Adjust width as needed
                    },
                }}
            >
                <List sx={{ fontWeight: "900" }}>
                    <img src={require("../images/goveda-logo.png")} height={50} style={{ margin: "20px" }} />
                    <br />
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/" onClick={handleDrawerClose}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/about" onClick={handleDrawerClose}>
                        <ListItemText primary="About" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/" onClick={handleDrawerClose}>
                        <ListItemText primary="Event" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/gallery" onClick={handleDrawerClose}>
                        <ListItemText primary="Gallery" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} onClick={handleDropdownClick}>
                        <ListItemText primary="Causes" />
                        {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={dropdownOpen} timeout="auto" unmountOnExit sx={{ mr: 1 }}>
                        <List component="div" disablePadding>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/causes/agnihotra-vruddhi" onClick={handleDrawerClose}>
                                <ListItemText primary="Agnihotra Vruddhi" />
                            </ListItem>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/causes/annadanam" onClick={handleDrawerClose}>
                                <ListItemText primary="Annadanam" />
                            </ListItem>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/causes/support-patashalas" onClick={handleDrawerClose}>
                                <ListItemText primary="Support Patashalas" />
                            </ListItem>
                            {/* <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/option4" onClick={handleDrawerClose}>
                                <ListItemText primary="Vaiddeehal Matrimony" />
                            </ListItem> */}
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/causes/village-temple-parayanam" onClick={handleDrawerClose}>
                                <ListItemText primary="Village Temple Parayanam" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/contact" onClick={handleDrawerClose}>
                        <ListItemText primary="Contact" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/dashboard" onClick={handleDrawerClose}>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </List>

            </Drawer>
        </Box>
    );
};

export default Header;
