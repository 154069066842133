import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import apiUrl from '../Api/api';

const AdminProtectedroute = ({ children }) => {

    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('adminToken');

        const url = `${apiUrl}/admin/admin-dashboard`;

        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
            })
            .catch((error) => {
                navigate("/admin")
            })

    }, [])


    return (
        <>{children}</>
    )
}

export default AdminProtectedroute;