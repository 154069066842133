import React, { useEffect, useState } from 'react';
import apiUrl from '../Api/api';
import axios from 'axios';
import AdminHeader from './AdminHeader';
import { Box, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import theme from "../components/Theme"

const AdminDashboard = () => {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const [totaldonors, setTotaldonors] = useState(0);
    const [monthlyRevenue, setMonthlyRevenue] = useState(0);
    const [todayRevenue, setTodayRevenue] = useState(0);
    const [donors, setdonors] = useState([]); // State for customer data

    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

    const barChartData = [
        { name: 'January', revenue: 4000 },
        { name: 'February', revenue: 3000 },
        { name: 'March', revenue: 2000 },
        { name: 'April', revenue: 2780 },
        { name: 'May', revenue: 1890 },
        { name: 'June', revenue: 2390 },
        { name: 'July', revenue: 3490 },

    ];

    useEffect(() => {
        // Simulating API calls with dummy values
        const fetchData = async () => {
            try {

                const donorsResponse = await axios.get(`${apiUrl}/admin/donors/total`);
                // const revenueMonthlyResponse = await axios.get(`${apiUrl}/admin/revenue/monthly`);
                // const revenueTodayResponse = await axios.get(`${apiUrl}/admin/revenue/today`);
                // const donorsListResponse = await axios.get(${apiUrl}/donors/list); // Fetch customer list

                // setTotaldonors(donorsResponse.data.total);
                // setMonthlyRevenue(revenueMonthlyResponse.data.revenue);
                // setTodayRevenue(revenueTodayResponse.data.revenue);
                setdonors(donorsResponse.data); // Adjust based on your response structure

                // Mock data
                // const donorsListResponse = {
                //     data: [
                //         { id: 1, name: 'John Doe', email: 'john@example.com', phone: '1234567890' },
                //         { id: 2, name: 'Jane Smith', email: 'jane@example.com', phone: '0987654321' },
                //         { id: 3, name: 'Alice Johnson', email: 'alice@example.com', phone: '1122334455' },
                //         { id: 1, name: 'John Doe', email: 'john@example.com', phone: '1234567890' },
                //         { id: 2, name: 'Jane Smith', email: 'jane@example.com', phone: '0987654321' },
                //     ],
                // };

                // setdonors(donorsListResponse.data); // Adjust based on your response structure
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when changing rows per page
    };


    return (
        // <ThemeProvider theme={theme}>
        <div className='admin-container'>
            <AdminHeader />
            <Box sx={{ flexGrow: 1, padding: 2, py: { xs: 5, md: 15 }, mt: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                            <Typography variant="h5">Total donors</Typography>
                            <Typography variant="h2" color="secondary">{donors.length}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                            <Typography variant="h5">Today's Revenue</Typography>
                            <Typography variant="h2" color="success">₹{todayRevenue}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                            <Typography variant="h5">Monthly Revenue</Typography>
                            <Typography variant="h2" color="primary">₹{monthlyRevenue}</Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={3} sx={{ marginTop: 4 }}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                            <Typography variant="h5">Revenue by Month</Typography>
                            <BarChart width={isMobile ? 300 : 500} height={300} data={barChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="revenue" fill="#8884d8" />
                            </BarChart>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3}>
                            <Typography component="a" href="admin-total-donors" marginLeft="80%" >
                                View All
                            </Typography>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {donors
                                            .slice()
                                            .reverse()
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((customer, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{customer.donor_id}</TableCell>
                                                    <TableCell>{customer.name}</TableCell>
                                                    <TableCell>{customer.email}</TableCell>
                                                    <TableCell>{customer.mobile}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={donors.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </div>
        // </ThemeProvider>
    );
};

export default AdminDashboard;
