import { useEffect, useState } from "react";
import {
    GetCountries,
    GetState,
    GetCity,
} from "react-country-state-city";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box,
} from "@mui/material";

const Rough = () => {
    const [countryid, setCountryid] = useState('');
    const [stateid, setStateid] = useState('');
    const [cityid, setCityid] = useState('');

    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
    }, []);

    const handleCountryChange = (e) => {
        const selectedCountry = countriesList.find(country => country.id === e.target.value);
        if (selectedCountry) {
            setCountryid(selectedCountry.id);
            setStateid(''); // Reset state and city when changing country
            setCityid('');
            GetState(selectedCountry.id).then((result) => {
                setStateList(result);
            });
        }
    };

    const handleStateChange = (e) => {
        const selectedState = stateList.find(state => state.id === e.target.value);
        if (selectedState) {
            setStateid(selectedState.id);
            setCityid(''); // Reset city when changing state
            GetCity(countryid, selectedState.id).then((result) => {
                setCityList(result);
            });
        }
    };

    const handleCityChange = (e) => {
        const selectedCity = cityList.find(city => city.id === e.target.value);
        if (selectedCity) {
            setCityid(selectedCity.id);
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6">Country</Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>Country</InputLabel>
                <Select
                    value={countryid}
                    onChange={handleCountryChange}
                    label="Country"
                >
                    {countriesList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography variant="h6">State</Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>State</InputLabel>
                <Select
                    value={stateid}
                    onChange={handleStateChange}
                    label="State"
                >
                    {stateList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography variant="h6">City</Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>City</InputLabel>
                <Select
                    value={cityid}
                    onChange={handleCityChange}
                    label="City"
                >
                    {cityList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default Rough;
