import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../components/Theme';
import {
    Box,
    Typography,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Grid,
    IconButton,
    Paper,
} from '@mui/material';
import LoginMenus from '../components/LoginMenus';
import apiUrl from '../Api/api';
import axios from 'axios';
import withAuth from '../HighOrderComponent/withAuth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import numWords from 'num-words';


const DownloadReceipt = ({ user, loading, error, handleLoading, handleError }) => {

    const [downloadReceipt, setDownloadReceipt] = useState([]);
    const [donorDetails, setDonorDetails] = useState({});
    const [selectedReceipts, setSelectedReceipts] = useState(null); // State to store selected receipts

    useEffect(() => {
        const fetchData = async () => {
            if (user && user.phoneNumber) {
                const url = `${apiUrl}/download-receipt?phoneNumber=${user.phoneNumber}`;
                try {
                    handleLoading(true);
                    const response = await axios.get(url);
                    setDownloadReceipt(response.data.donations);
                    setDonorDetails(response.data.donor);
                    handleLoading(false);
                } catch (error) {
                    handleError(error.response.data.message || error.response.data.error);
                    console.log(error);
                }
            }
        };

        fetchData();
    }, [user]);


    const receiptRef = React.useRef();


    const receiptDownload = (receipt) => {

        const amount = receipt.amount;
        const receipt_id = receipt.receipt_id;
        const payment_mode = receipt.payment_mode;
        const cheque_date = receipt.cheque_date;
        const cheque_number = receipt.cheque_number;
        const bank_name = receipt.bank_name;
        const bank_branch = receipt.bank_branch;
        const govedaBank = receipt.govedaBank;
        const ref_number = receipt.ref_number;
        const otherPurposes = receipt.other_purpose;

        let purpose;
        if (receipt.purpose === "OTHERS") {
            purpose = receipt.other_purpose;
        } else {
            purpose = receipt.purpose;
        }
        const amountInWords = numWords(amount);
        const payment_date = new Date(receipt.payment_date).toLocaleString("en-GB", { dateStyle: "short" });

        // Set selected receipts state (optional if you don't need to keep it)
        setSelectedReceipts(prevState => ({
            ...prevState,
            amount,
            payment_mode,
            amountinWords: amountInWords,
            purpose,
            otherPurposes,
            receipt_id,
            payment_date,
            cheque_date,
            cheque_number,
            bank_branch,
            bank_name,
            ref_number,
            govedaBank
        }));

        // Introduce a 2-second delay before proceeding with the PDF generation
        setTimeout(() => {
            html2canvas(receiptRef.current, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'a4'
                });

                // A4 dimensions in mm
                const a4Width = 210; // mm
                const a4Height = 297; // mm
                const margin = 5; // 5mm margin

                const imgWidth = a4Width - margin * 2; // Adjust for left and right margins
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                // Add the image to the PDF
                let positionY = margin; // Starting position from top
                pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);

                // Check if the image height exceeds A4 page height
                let heightLeft = imgHeight - (a4Height - margin * 2);
                while (heightLeft >= 0) {
                    pdf.addPage();
                    positionY = heightLeft - imgHeight + margin; // Maintain top margin on new pages
                    pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);
                    heightLeft -= a4Height;
                }

                pdf.save(`Goveda_Receipt_${receipt.receipt_id}.pdf`); // Save the PDF
            });
            setSelectedReceipts(null)
        }, 2000); // Delay in milliseconds (2000 ms = 2 seconds)
    };

    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container sx={{ py: 5, maxWidth: 'lg' }}>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', }}>
                    Download Receipts
                </Typography>
                {error && <Typography sx={{ p: 2 }} color="error">{error}</Typography>}
                {loading && <Typography sx={{ p: 2 }}>Loading...</Typography>}

                <Box sx={{ mb: 10 }}>
                    <LoginMenus />
                </Box>

                {downloadReceipt.length > 0 && (
                    <Grid container spacing={3}>
                        {downloadReceipt.slice().reverse().map((receipt, index) => (
                            <Grid item xs={12} sm={6} md={6} key={receipt.receipt_id}>
                                <Accordion sx={{ boxShadow: 3, borderRadius: 2 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#4b769f' }} />}
                                        aria-controls={`panel-${receipt.receipt_id}-content`}
                                        id={`panel-${receipt.receipt_id}-header`}
                                        sx={{
                                            backgroundColor: '#f5f8fa',
                                            '&:hover': { backgroundColor: '#e7ebf0' },
                                            borderRadius: 1,
                                            padding: 1,
                                            transition: 'all 0.3s ease',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                            <Typography variant="body5" sx={{ color: '#333', marginRight: 2 }}>
                                                {`${index + 1}.`}
                                            </Typography>
                                            <Typography variant="body5" sx={{ color: '#333', marginRight: 2, fontWeight: "bold" }}>
                                                {receipt.purpose}
                                            </Typography>
                                            <Typography variant="body5" sx={{ color: '#333' }}>
                                                - {new Date(receipt.payment_date).toLocaleString("en-GB", {
                                                    dateStyle: "short",
                                                    timeZone: "UTC",
                                                    hour12: true,
                                                })}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <DownloadIcon sx={{ color: "seagreen", marginLeft: 2, marginRight: 1 }} />
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ backgroundColor: '#fafafa', p: 2 }}>
                                        <Box>
                                            <Typography variant="body1" sx={{ color: '#555', mb: 1 }}>
                                                <strong>Amount:</strong> {receipt.amount}
                                            </Typography>
                                            <Typography variant="body1" sx={{ color: '#555', mb: 1 }}>
                                                <strong>Payment Mode:</strong> {receipt.payment_mode}
                                            </Typography>
                                            <Typography variant="body1" sx={{ color: '#555', mb: 1 }}>
                                                <strong>Other Purpose:</strong> {receipt.other_purpose || 'N/A'}
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ mt: 2, borderRadius: '4px', color: "white" }}
                                                onClick={() => receiptDownload(receipt)}
                                            >
                                                Download Receipt
                                            </Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {
                    selectedReceipts &&

                    <Box sx={{ p: 3, maxWidth: 'lg', margin: '0 auto', overflow: "auto" }} >
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 1.5,
                                border: "2px solid black",
                                m: 2,
                                width: '210mm', // Fixed width for A4
                                height: '297mm', // Fixed height for A4
                                boxSizing: 'border-box', // Include padding and border in the element's total width and height
                            }}
                            ref={receiptRef}
                        >
                            <Grid container alignItems="center" sx={{ borderBottom: "1px solid grey", p: 1 }}>
                                <Grid item xs={6}>
                                    <Box>
                                        <img src={require("../images/goveda-logo.png")} alt="GoVeda Logo" height={50} />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box textAlign="right">
                                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                            GoVeda Foundation
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                            Functional Office: Door No. 4D1, Fourth Floor, Tower 1, <br />
                                            Shakthi Towers, #766, Anna Salai, Chennai - 600 002. <br />
                                            Phone: 044 48607558 / 98416 26380
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                            Email: info@govedafoundation.org <br />
                                            Website: www.govedafoundation.org <br />
                                            Registration No: 397/2017/BK4
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box sx={{ my: 2 }}>
                                <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                                    Thank You Letter Cum Receipt
                                </Typography>

                                <Grid container alignItems="center" sx={{ mt: 2, }}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" align="left" >
                                            Dear Shri <Box component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{donorDetails.name}</Box>,
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sx={{ textAlign: 'right', fontSize: '0.9rem' }}>
                                        <Typography variant="subtitle1">
                                            Date: <Box component="span" sx={{ fontWeight: 'bold', }}>{selectedReceipts.payment_date}</Box>
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Box>

                            <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                Thank you for your valuable contribution towards the cause of <Box component="span" sx={{ fontWeight: 'bold', }}>{selectedReceipts.purpose === 'Others' ? selectedReceipts.otherPurposes : selectedReceipts.purpose}</Box>. We at GoVeda Foundation are
                                committed to serving humanity by preserving the age-old knowledge bestowed upon us by our Rishis and
                                forefathers.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                                We welcome you into this sathsanga of like-minded people who share their resources for activities that
                                will help leave a legacy for our future generations.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                                We also invite you to be volunteers for our various activities as and when required at your convenience.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                                We look forward to your continued patronage.
                            </Typography>

                            <Box sx={{ mt: 3, mb: 3, borderTop: "1px solid grey", p: 3 }} >
                                <Typography variant="h5" align='center' sx={{ fontWeight: "bold", }}>Receipt</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }}>Receipt No.: {selectedReceipts.receipt_id}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }} align="right">Date: <Box component="span" sx={{ fontWeight: 'bold', }}>{selectedReceipts.payment_date}</Box></Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                Received with thanks from {donorDetails.prefix} <Box component="span" sx={{ fontWeight: 'bold' }}>{donorDetails.name}</Box>,
                                address: <Box component="span" sx={{ fontWeight: 'bold' }}>{donorDetails.address1}, {donorDetails.address2}, {donorDetails.city_name}, {donorDetails.state_name}, {donorDetails.pincode}</Box>,
                                the sum of Rupees <Box component="span" sx={{ fontWeight: 'bold', textTransform: "capitalize" }}>{selectedReceipts.amountinWords}</Box> only towards <Box component="span" sx={{ fontWeight: 'bold' }}>{selectedReceipts.purpose === 'Others' ? selectedReceipts.otherPurposes : selectedReceipts.purpose}</Box> by
                                {selectedReceipts.payment_mode === 'Cheque' && (
                                    <>
                                        {" "}<Box component="span" sx={{ fontWeight: 'bold' }}>{selectedReceipts.payment_mode}</Box> No.* <Box component="span" sx={{ fontWeight: 'bold' }}>{selectedReceipts.cheque_number}</Box>, dated <Box component="span" sx={{ fontWeight: 'bold' }}>{new Date(selectedReceipts.cheque_date).toLocaleString("en-GB", { dateStyle: "short", timeZone: "UTC" })}</Box>, drawn on <Box component="span" sx={{ fontWeight: 'bold' }}>{selectedReceipts.govedaBank}</Box>
                                    </>
                                )}
                                {selectedReceipts.payment_mode === 'Bank Transfer' && (
                                    <Box component="span" sx={{ fontWeight: 'bold' }}>{" "}ONLINE</Box>
                                )}
                                {selectedReceipts.payment_mode !== 'Bank Transfer' && selectedReceipts.payment_mode !== 'Cheque' && (
                                    <Box component="span" sx={{ fontWeight: 'bold' }}>{selectedReceipts.payment_mode}</Box>
                                )}
                                {selectedReceipts.ref_number && (
                                    <>, Reference No. <Box component="span" sx={{ fontWeight: 'bold' }}>{selectedReceipts.ref_number}</Box></>
                                )}.
                            </Typography>




                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" align="left" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                        Rs.<Box component="span" sx={{ fontWeight: 'bold', }}>{selectedReceipts.amount}.00</Box>
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Box sx={{ mb: 1 }}>
                                        <img src={require("../images/PDF Signature/suryaSignature.png")} alt="Signature" height={150} width={200} />
                                    </Box>

                                    <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                        For GoVeda Foundation
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>

                }

            </Container>
            <Footer />
        </ThemeProvider>
    );
};



export default withAuth(DownloadReceipt);
