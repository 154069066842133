import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../Api/api';

const LoginProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null); // State to hold user information

    useEffect(() => {
        const url = `${apiUrl}/protected-route`;

        const fetchUser = async () => {
            try {
                // Get token from localStorage
                const token = localStorage.getItem('loginToken');

                // Send the token in the Authorization header
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });

                setUser(response.data.user); // Set user data from the response
            } catch (error) {
                console.log('Error:', error.response?.data?.message || error.message);
                navigate("/dashboard"); // Redirect to login/dashboard page
            }
        };

        fetchUser();
    }, [navigate]);

    return (
        <>
            {React.cloneElement(children, { user })} {/* Pass user to child component */}
        </>
    );
}

export default LoginProtectedRoute;
