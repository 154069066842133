import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../components/Theme';
import {
    Box,
    Typography,
    Container,
} from '@mui/material';
import DashboardLogin from '../components/DashboardLogin';
import Cookies from 'js-cookie';
import LoginMenus from '../components/LoginMenus';
import apiUrl from '../Api/api';
import axios from 'axios';

const Dashboard = ({ loading, error, handleLoading, handleError }) => {


    const [isLogin, setLogin] = useState(null)

    useEffect(() => {
        const url = `${apiUrl}/protected-route`;

        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('loginToken');
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                setLogin(true);
            } catch (error) {
                console.log(error);
                setLogin(false);
            }
        };

        fetchUser();
    }, []);



    return (
        <ThemeProvider theme={theme}>

            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />

            <Container sx={{ mb: 30 }}>
                {error && <Typography sx={{ p: 2 }} color="error">{error}</Typography>}
                {loading && <Typography sx={{ p: 2 }}>Loading...</Typography>}

                <Box my={5}>

                    {!isLogin &&
                        <Typography variant="h5" gutterBottom sx={{ textAlign: "center", }}>
                            Donor Dashboard
                        </Typography>
                    }



                    <Box mt={4}>
                        {isLogin ? (
                            <>
                                <LoginMenus />

                                <Typography variant="h5" gutterBottom sx={{ textAlign: "center", }}>
                                    Donor Dashboard
                                </Typography>
                            </>
                        ) : (
                            <DashboardLogin onLoginSuccess={() => setLogin(true)} />
                        )}
                    </Box>

                </Box>
            </Container>

            <Footer />
        </ThemeProvider >
    );
};

export default Dashboard;
