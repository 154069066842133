import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Container, Stack, MenuItem, InputLabel, FormControl, Select, Paper, Grid, InputAdornment, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import { CalendarMonth } from '@mui/icons-material';

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
    GetCity,
    GetState,
    GetCountries,
} from 'react-country-state-city';
import 'react-country-state-city/dist/react-country-state-city.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ToWords } from 'to-words';
import withAuth from '../../HighOrderComponent/withAuth';


const toWords = new ToWords({
    localeCode: 'en-IN', // You can change locale if needed
    converterOptions: {
        currency: false,
        ignoreDecimal: true,
    },
});

const ReceiptNewDonor = ({ loading, error, handleLoading, handleError, onDownloadReceipt }) => {

    const [countryid, setCountryid] = useState('');
    const [stateid, setStateid] = useState('');
    const [cityid, setCityid] = useState('');

    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);


    const [formData, setformData] = useState({
        donor_category: '',
        rep_id: '0',
        prefix: '',
        name: '',
        isd_code: '91',
        mobile: '',
        email: '',
        address1: '',
        address2: '',
        city_name: '',
        state_name: '',
        country_name: '',
        pincode: '',
        pan: '',
        admin_id: '1',
        status: 'Y', // Default status can be 'A' for Active
        amountinNumbers: '',
        amountinWords: '',
        paymentMode: '',
        paymentDate: '',
        supportOption: '',
        otherPurposes: '',
        chequeNumber: '',
        chequeDate: '',
        bankName: '',
        bankBranch: '',
        refNumber: '',
        type: "New Donor"
    });

    // payment mode
    const handlePaymentModeClick = (mode) => {
        setformData({ ...formData, paymentMode: mode });
    };

    // amount onchange
    const handleAmount = (e) => {
        const { name, value } = e.target;

        if (name === 'amountinNumbers') {
            const amountInWords = toWords.convert(value || 0); // Convert number to words
            setformData({
                ...formData,
                amountinNumbers: value,
                amountinWords: amountInWords, // Set the converted value to the amountinWords field
            });
        } else {
            setformData({ ...formData, [name]: value });
        }
    };

    // textfields onchange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);


    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
    }, []);

    // Handle radio button change
    const handleRadioChange = (event) => {

        const { name, value } = event.target;
        setSelectedOption(value);
        console.log(value);
        setformData((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        // Update dropdown options based on selected radio button
        if (value === 'Individual') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        } else if (value === 'Institution') {
            setDropdownOptions(['M/s']);
        } else if (value === 'Representative') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        }
    };

    const handleCountryChange = (e) => {

        const selectedCountry = countriesList.find(country => country.id === e.target.value);
        console.log(selectedCountry);
        if (selectedCountry) {
            setCountryid(selectedCountry.id);
            setStateid(''); // Reset state and city when changing country
            setCityid('');
            GetState(selectedCountry.id).then((result) => {
                setStateList(result);
            });
        }
        setformData(prev => ({ ...prev, country_name: selectedCountry.name }));

    };

    const handleStateChange = (e) => {
        const selectedState = stateList.find(state => state.id === e.target.value);
        if (selectedState) {
            setStateid(selectedState.id);
            setCityid(''); // Reset city when changing state
            GetCity(countryid, selectedState.id).then((result) => {
                setCityList(result);
            });
        }
        setformData(prev => ({ ...prev, state_name: selectedState.name }));

    };

    const handleCityChange = (e) => {
        const selectedCity = cityList.find(city => city.id === e.target.value);
        if (selectedCity) {
            setCityid(selectedCity.id);
        }
        setformData(prev => ({ ...prev, city_name: selectedCity.name }));

    };

    // submit and downlaod
    const downloadReceipt = async (e) => {

        console.log(formData);
        onDownloadReceipt(formData);
        e.preventDefault();
        setformData({
            donor_category: '',
            rep_id: '0',
            prefix: '',
            name: '',
            isd_code: '91',
            mobile: '',
            email: '',
            address1: '',
            address2: '',
            city_name: '',
            state_name: '',
            country_name: '',
            pincode: '',
            pan: '',
            admin_id: '1',
            status: 'Y', // Default status can be 'A' for Active
            amountinNumbers: '',
            amountinWords: '',
            paymentMode: '',
            paymentDate: '',
            supportOption: '',
            chequeNumber: '',
            chequeDate: '',
            bankName: '',
            bankBranch: '',
            refNumber: '',
            govedaBank: '',
            type: "New Donor"
        });

    };



    return (

        <Box>

            <form onSubmit={downloadReceipt}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ p: 2 }}>
                        <Typography variant='h5'>Donor Details</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth>
                            <RadioGroup
                                row
                                aria-label="user-type"
                                name="donor_category"
                                value={selectedOption}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                                <FormControlLabel value="Institution" control={<Radio />} label="Institution" />
                                <FormControlLabel value="Representative" control={<Radio />} label="Representative" />
                            </RadioGroup>
                        </FormControl>

                        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} width="100%" mt={2}>
                            <FormControl variant="outlined" sx={{ minWidth: 120, maxWidth: 300 }}>
                                <InputLabel>{selectedOption} Prefix</InputLabel>
                                <Select
                                    value={formData.prefix}
                                    onChange={handleChange}
                                    name="prefix"
                                    label={`${selectedOption} Prefix`}
                                >
                                    {dropdownOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type='email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" width="100%">
                            {/* ISD Code Input */}
                            <PhoneInput
                                country={'in'}
                                value={formData.isd_code}
                                onChange={(value) => {
                                    setformData({
                                        ...formData,
                                        isd_code: value, // Store ISD code
                                    });
                                    console.log(value); // Log the phone number including the ISD code
                                }}
                                inputProps={{
                                    maxLength: 8, // Limit the length of the ISD code
                                }}
                            />

                            {/* Mobile Number Input */}
                            <TextField
                                fullWidth
                                label="Mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                                inputProps={{
                                    maxLength: 10, // Limit the length of the ISD code
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}> {/* Adjust the size for responsiveness */}
                                <TextField
                                    fullWidth
                                    label="Address 1"
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> {/* Adjust the size for responsiveness */}
                                <TextField
                                    fullWidth
                                    label="Address 2"
                                    name="address2"
                                    value={formData.address2}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="PAN"
                            name="pan"
                            value={formData.pan}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Country, State, City Selects */}
                    <Grid item xs={12} sm={6} >

                        <FormControl fullWidth variant="outlined" >
                            <InputLabel>Country</InputLabel>
                            <Select
                                value={countryid}
                                onChange={handleCountryChange}
                                label="Country"
                            >
                                {countriesList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" >
                            <InputLabel>State</InputLabel>
                            <Select
                                value={stateid}
                                onChange={handleStateChange}
                                label="State"
                            >
                                {stateList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <FormControl fullWidth variant="outlined" >
                            <InputLabel>City</InputLabel>
                            <Select
                                value={cityid}
                                onChange={handleCityChange}
                                label="City"
                            >
                                {cityList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
                <br />
                <Divider />

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} sx={{ p: 2 }}>
                        <Typography variant='h5'>Donation Details</Typography>
                    </Grid>
                    {/* purpose */}
                    <Grid item xs={12} sm={6}>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel> Support Options</InputLabel>
                            <Select
                                label="Support Options"
                                name="supportOption"
                                value={formData.supportOption}
                                onChange={handleChange}
                                sx={{
                                    '&:focus': {
                                        borderColor: '#38a1f7', // Change border color on focus
                                    },
                                }}
                            >
                                <MenuItem value="General Fund">General Fund</MenuItem>
                                <MenuItem value="Corpus Fund">Corpus Fund</MenuItem>
                                <MenuItem value="Support Patashala">Support Patashala</MenuItem>
                                <MenuItem value="Samaradanai">SAMARADANAI</MenuItem>
                                <MenuItem value="Annadanam">Annadanam</MenuItem>
                                <MenuItem value="Village Parayanam">Village Parayanam</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </Select>
                        </FormControl>

                        {/* other purposes */}
                        {formData.supportOption === 'Others' && (
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Please specify"
                                name="otherPurposes"
                                value={formData.otherPurposes || ''}
                                onChange={handleChange}
                                required={formData.supportOption === 'Others'}
                                sx={{
                                    marginTop: 2, // Add space between the Select and TextField
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#38a1f7', // Change border color on focus
                                        },
                                    },
                                }}
                            />
                        )}

                    </Grid>
                    {/* amount in numbers */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Amount in Numbers"
                            name="amountinNumbers"
                            value={formData.amountinNumbers}
                            onChange={handleAmount}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        Rs.
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            type='number'
                            sx={{ bgcolor: 'white' }}
                            required
                        />
                    </Grid>
                    {/* payment mode */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="center" gutterBottom sx={{ p: 2 }}>
                            Choose Payment Mode
                        </Typography>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<AttachMoneyIcon />}
                                    variant={formData.paymentMode === 'CASH' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('CASH')}
                                >
                                    CASH
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<QrCodeIcon />}
                                    variant={formData.paymentMode === 'UPI' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('UPI')}
                                >
                                    UPI
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<PaymentIcon />}
                                    variant={formData.paymentMode === 'Cheque' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('Cheque')}
                                >
                                    Cheque
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<AccountBalanceIcon />}
                                    variant={formData.paymentMode === 'Bank Transfer' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('Bank Transfer')}
                                >
                                    Bank Transfer
                                </Button>
                            </Grid>

                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Reference Number
                                </Typography>
                                <TextField
                                    label="Reference Number"
                                    name="refNumber"
                                    value={formData.refNumber}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="Enter reference number"
                                />
                            </Box>


                            {/* Conditional rendering for cheque details */}
                            {formData.paymentMode === 'Cheque' && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Cheque Details
                                    </Typography>
                                    <TextField
                                        label="Cheque Number"
                                        name="chequeNumber"
                                        value={formData.chequeNumber}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter cheque number"
                                    />
                                    <TextField
                                        label="Cheque Date"
                                        name="chequeDate"
                                        type="date"
                                        value={formData.chequeDate}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <Typography variant='caption' color='textSecondary'> credited to :</Typography>
                                    <TextField
                                        label="Goveda Bank"
                                        name="govedaBank"
                                        value={formData.govedaBank}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter Goveda Bank name"
                                    />
                                </Box>
                            )}

                            {/* Conditional rendering for bank transfer details */}
                            {formData.paymentMode === 'Bank Transfer' && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Bank Transfer Details
                                    </Typography>
                                    <TextField
                                        label="Bank Name"
                                        name="bankName"
                                        value={formData.bankName}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter bank name"
                                    />
                                    <TextField
                                        label="Bank Branch"
                                        name="bankBranch"
                                        value={formData.bankBranch}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter branch name"
                                    />

                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    {/* payment date */}
                    <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                        <TextField
                            name="paymentDate"
                            label="Payment Date"
                            type="date"
                            value={formData.paymentDate}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: 'white' }}
                        />
                    </Grid>


                    {/* Submit Button */}
                    <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                        <Button variant="contained" color="primary" type="submit" size='large' sx={{ mt: 2, maxWidth: 400, p: 2 }} disabled={formData.paymentMode === ""}>
                            Download Receipt
                        </Button>
                    </Box>

                </Grid>
            </form>

        </Box>
    )
}

export default withAuth(ReceiptNewDonor);